import QueryUtils from "@/utils/QueryUtils";
import axios from "axios";
import CounterpartyDTO from "@/dto/company/CounterpartyDTO";
import {COUNTERPARTY_ENDPOINT, COUNTERPARTY_PROSPECTIVE_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import Pageable from "@/dto/Pageable";
import ProspectiveCounterpartyDTO from "@/dto/company/ProspectiveCounterpartyDTO";

class CounterpartyService {

    getCounterparties(companyId: number, isResident: boolean | null, page: number) {
        const queryString = QueryUtils.buildQueryString(
            ['companyId', companyId],
            ['isResident', isResident],
            ['page', page]
        );
        return axios.get<Pageable<CounterpartyDTO>>(COUNTERPARTY_ENDPOINT + queryString, {headers: authHeader()});
    }

    getProspectiveCounterparties(companyId: number, isResident: boolean | null, page: number) {
        const queryString = QueryUtils.buildQueryString(
            ['companyId', companyId],
            ['isResident', isResident],
            ['page', page]
        );
        return axios.get<Pageable<ProspectiveCounterpartyDTO>>(COUNTERPARTY_PROSPECTIVE_ENDPOINT + queryString, {headers: authHeader()});
    }

    addCounterparty(companyId: number, counterpartyId: number, counterpartyType: string) {
        return axios.post<CounterpartyDTO>(COUNTERPARTY_ENDPOINT, {
            companyId: companyId,
            counterpartyId: counterpartyId,
            counterpartyType: counterpartyType
        }, {headers: authHeader()});
    }

}

export default new CounterpartyService();