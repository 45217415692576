























import {Component, Vue} from "vue-property-decorator";
import RouteNames from "@/router/RouteNames";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import {namespace} from "vuex-class";
import SelectShareholderModal from "@/components/common/SelectShareholderModal.vue";
import Workspace from "@/dto/auth/Workspace";
import ShareholderDTO from "@/dto/company/ShareholderDTO";
import SelectCounterpartyModal from "@/components/common/SelectCounterpartyModal.vue";
import CounterpartyDTO from "@/dto/company/CounterpartyDTO";
import Workspaces from "@/state/Workspaces";
import SelectContractorTypeModal from "@/components/payroll/business/SelectContractorTypeModal.vue";
import {COMPANY_ID, CONTRACTOR_TYPE} from "@/constants/Paths";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class IndividualPayrollForms extends Vue {

  @AppModule.State
  private isMobile!: boolean;

  goToW2() {
    this.$router.push({name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES, params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.F_W2}});
  }

  goTo1099() {
    const params: Record<string, string> = {};
    params[COMPANY_ID] = `${Workspaces.getCurrent.id}`;
    this.$router.push({name: RouteNames.PAYROLL_BUSINESS_FORMS_1099, params: params});
  }

  goTo1042S() {
    this.$modal.show(
        SelectCounterpartyModal,
        {
          filter: {
            companyId: Workspaces.getCurrent.id,
            isResident: false
          },
          addable: true,
          onCounterpartySelect: (counterparty: CounterpartyDTO) => this.onCounterpartyFor1042SSelected(counterparty)
        }
    )
  }

  onCounterpartyFor1042SSelected(counterparty: CounterpartyDTO) {
    this.$router.push({name: RouteNames.PAYROLL_BUSINESS_FORMS_1042S, params: {'counterpartyId': `${counterparty.id}`}});
  }

  goToK1() {
    this.$modal.show(
        SelectShareholderModal,
        {
          companyId: Workspaces.getCurrent.id,
          onSelect: (shareholder: ShareholderDTO) => this.onShareholderForK1Selected(shareholder)
        }
    )
  }

  onShareholderForK1Selected(shareholder: ShareholderDTO) {
    this.$router.push({name: RouteNames.PAYROLL_BUSINESS_FORMS_K1, params: {'shareholderId': `${shareholder.id}`}});
  }

}
