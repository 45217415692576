





































import {Component, Prop, Vue} from "vue-property-decorator";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import ShareholderDTO from "@/dto/company/ShareholderDTO";

const AppModule = namespace("App");

@Component
export default class SelectShareholderModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private message = "";

  private successful = true;

  @Prop()
  private companyId!: number;

  private shareholders: Array<ShareholderDTO> = [];

  @Prop()
  private onSelect!: (shareholder: ShareholderDTO) => void;

  mounted() {
    this.loadShareholders();
  }

  loadShareholders() {
    this.message = "";
    this.startLoading();
    CompanyService.getAllShareHolderByCompanyId(this.companyId).then(
        response => {
          this.shareholders = response.data
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  select(sh: ShareholderDTO) {
    this.$modal.hideAll();
    this.onSelect(sh);
  }

}
