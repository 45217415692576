








































































































import {Component, Prop, Vue} from "vue-property-decorator";
import CounterpartyDTO from "@/dto/company/CounterpartyDTO";
import {namespace} from "vuex-class";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import CounterpartyService from "@/services/CounterpartyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import ProspectiveCounterpartyDTO from "@/dto/company/ProspectiveCounterpartyDTO";

const AppModule = namespace("App");


@Component({
  components: {PaginationComponent}
})
export default class SelectCounterpartyModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private message = "";

  private successful = true;

  @Prop()
  private filter!: { companyId: number, isResident: boolean | null };

  @Prop()
  private onCounterpartySelect!: (counterparty: CounterpartyDTO) => void;

  @Prop({default: false})
  private addable!: boolean;

  private counterparties: Array<CounterpartyDTO> = [];

  private cPage = 1;

  private totalCPages = 1;

  private addMode = false;

  private prospective: Array<ProspectiveCounterpartyDTO> = [];

  private pPage = 1;

  private totalPPages = 1;

  mounted() {
    this.loadCounterparties();
  }

  loadCounterparties() {
    this.message = "";
    this.startLoading();
    CounterpartyService.getCounterparties(this.filter.companyId, this.filter.isResident, this.cPage).then(
        response => {
          this.counterparties = response.data.data;
          this.totalCPages = response.data.countOfPages;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )
  }

  onAddMode() {
    this.addMode = true;
    this.loadProspective();
  }

  private loadProspective() {
    this.message = "";
    this.startLoading();
    CounterpartyService.getProspectiveCounterparties(this.filter.companyId, this.filter.isResident, this.cPage).then(
        response => {
          this.prospective = response.data.data;
          this.totalPPages = response.data.countOfPages;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  select(counterparty: CounterpartyDTO) {
    this.onCounterpartySelect(counterparty);
    this.$modal.hideAll();
  }

  add(c: ProspectiveCounterpartyDTO) {
    this.message = "";
    this.startLoading();
    CounterpartyService.addCounterparty(this.filter.companyId, c.id, c.type).then(
        response => {
          this.successful = true;
          this.stopLoading();
          this.select(response.data);
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )

  }

}
