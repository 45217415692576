import { render, staticRenderFns } from "./SelectShareholderModal.vue?vue&type=template&id=ba462326&scoped=true&"
import script from "./SelectShareholderModal.vue?vue&type=script&lang=ts&"
export * from "./SelectShareholderModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba462326",
  null
  
)

export default component.exports